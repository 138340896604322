import React, { Fragment, useCallback, useEffect, useContext, useState } from 'react';
import { useIntl } from 'react-intl-next';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import {
	VIEW_PAGE_RESTRICTIONS_BUTTON_EXPERIENCE,
	ExperienceSuccess,
	SUBMIT_GUEST_UPGRADE_FLOW_BY_RESTRICTIONS_EXPERIENCE,
} from '@confluence/experience-tracker';
import {
	PageSegmentLoadEnd,
	SSRMouseEventWrapper,
	SSR_RESTRICTIONS_BUTTON_METRIC,
} from '@confluence/browser-metrics';
import { SSRActionLoadingSpinner } from '@confluence/ssr-utilities';
import { getMark } from '@confluence/performance';
import { RoutesContext } from '@confluence/route-manager';
// TODO(brizvash): allow color and size changes for icons
import { useSubmitSSRScriptErrors } from '@confluence/ssr-scripts-utils';
import { usePageRestrictionsContext, useContentId } from '@confluence/page-restrictions-context';
import { useIsExternalCollaborator } from '@confluence/external-collab-ui/entry-points/useIsExternalCollaborator';
import { GuestRequestToUpgradeByRestrictions } from '@confluence/external-collab-ui/entry-points/GuestRequestToUpgradeByRestrictions';
import { GuestRequestToUpgradeNotificationsWrapper } from '@confluence/external-collab-ui/entry-points/GuestRequestToUpgradeNotificationsWrapper';
import { DialogsStateContainer } from '@confluence/dialogs';
import { FlagsStateContainer } from '@confluence/flags';

import { RESTRICTIONS_BUTTON_METRIC } from '../perf.config';
import { RestrictionsDataContainer } from '../RestrictionsDialog/RestrictionsDataContainer';

import { RestrictionsButtonPlaceholder } from './RestrictionsButtonPlaceholder';
import { RestrictionButtonComponent } from './RestrictionsButtonComponent';
import type { RestrictionButtonComponentProps } from './RestrictionsButtonComponent';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const RestrictionButtonContainer = styled.span`
	button {
		font-size: 14px;
	}
`;

// We are using this container as a hacky way to hide the button when the user is a Guest (External
// Collaborator).  Normally we would just not render the component if we wanted to hide it, but in
// Fabric editor this button is rendered by a function passed as a prop to the AkEditor control,
// and the AkEditor will throw an error if the function doesn't render anything. So instead we will
// render the placeholder but hide it via CSS if the user is a Guest.  This makes the AkEditor
// happy while not showing the button.
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const PlaceholderContainer = styled.div<{ isGuest: boolean }>`
	display: ${(props) => (props.isGuest ? 'none' : 'block')};
`;

export const RestrictionsButton = (props: RestrictionsButtonProps) => {
	const {
		className,
		onClick,
		isDisabled,
		shouldHideSpinner,
		resetDialogState,
		restrictionsButtonSSRRendered = false,
		restrictionsButtonSSRLocation = '',
		triggeredFrom = '',
		pageRestrictionsExperimentCohort,
		shouldRenderGuestUpgradeByRestrictions = false,
	} = props;
	const contentId = useContentId(props.contentId);
	const intl = useIntl();
	const [showFlag, setShowFlag] = useState<boolean>(false);

	const { isExternalCollaborator: isGuest } = useIsExternalCollaborator();

	useSubmitSSRScriptErrors('restrictions-button');

	const isRestrictionsButtonSSRRendered: boolean = Boolean(
		window.__SSR_RENDERED__ && restrictionsButtonSSRRendered,
	);

	const isRestrictionsButtonClickedInSSR = (): boolean => {
		return Boolean(
			window.__SSR_EVENTS_CAPTURE__?.restrictionsButton &&
				(window.__SSR_RENDERED__ || process.env.REACT_SSR) &&
				isRestrictionsButtonSSRRendered,
		);
	};

	const restrictionsButtonClicked = useCallback(
		(
			evt: any,
			restrictionsDataContainer: RestrictionsDataContainer,
			hasRestrictions: boolean,
			hasInheritedRestrictions: boolean,
			hasDirectViewRestrictions: boolean,
		) => {
			if (isRestrictionsButtonSSRRendered) {
				if (!window.__SSR_EVENTS_CAPTURE__) {
					window.__SSR_EVENTS_CAPTURE__ = {};
				}

				window.__SSR_EVENTS_CAPTURE__.restrictionsButton = evt;
			}

			restrictionsDataContainer.setData({
				hasRestrictions,
				hasInheritedRestrictions,
				hasDirectViewRestrictions,
			});

			setShowFlag(true);
			onClick();
		},
		[isRestrictionsButtonSSRRendered, onClick],
	);

	const { transitionId } = useContext(RoutesContext);

	useEffect(() => {
		if (resetDialogState && transitionId) {
			resetDialogState();
			if (window?.__SSR_EVENTS_CAPTURE__?.restrictionsButton) {
				delete window?.__SSR_EVENTS_CAPTURE__?.restrictionsButton;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transitionId]);

	useEffect(() => {
		if (isRestrictionsButtonClickedInSSR()) {
			setShowFlag(true);
			onClick();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			shouldHideSpinner &&
			window.__SSR_EVENTS_CAPTURE__ &&
			window?.__SSR_EVENTS_CAPTURE__?.restrictionsButton
		) {
			delete window.__SSR_EVENTS_CAPTURE__?.restrictionsButton;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [shouldHideSpinner]);

	useEffect(() => {
		if (isRestrictionsButtonSSRRendered) {
			//set FMP now if SSR rendered for default restrictions dialog
			RESTRICTIONS_BUTTON_METRIC.markFMP(getMark('CFP-63.ssr-ttr'));
		}
	}, [isRestrictionsButtonSSRRendered]);

	const restrictionButtonGenerator = useCallback(
		(
			restrictionsDataContainer: RestrictionsDataContainer,
			hasDirectViewRestrictions: boolean,
			hasInheritedViewRestrictions: boolean,
			hasRestrictions: boolean,
			dialogs: DialogsStateContainer,
			flags: FlagsStateContainer,
		) => {
			return (
				<Fragment>
					{process.env.REACT_SSR && !shouldRenderGuestUpgradeByRestrictions && (
						<SSRActionLoadingSpinner
							spinnerId="restrictions-loading-spinner"
							actionType="restrictionsButton"
						/>
					)}
					<GuestRequestToUpgradeNotificationsWrapper
						dialogs={dialogs}
						flags={flags}
						experienceTrackerName={SUBMIT_GUEST_UPGRADE_FLOW_BY_RESTRICTIONS_EXPERIENCE}
						flagTitle={intl.formatMessage(GuestRequestToUpgradeByRestrictions.flagTitle)}
						requestUpgradeFlagDescription={intl.formatMessage(
							GuestRequestToUpgradeByRestrictions.requestUpgradeFlagDescription,
						)}
						requestPendingFlagDescription={intl.formatMessage(
							GuestRequestToUpgradeByRestrictions.requestPendingFlagDescription,
						)}
						touchpoint="restrictionsButton"
						flagActionSubjectId="guestRestrictionRequestAccess"
						showFlag={showFlag}
						updateFlagState={(flagState: boolean) => {
							setShowFlag(flagState);
						}}
					>
						<SSRMouseEventWrapper metricName={SSR_RESTRICTIONS_BUTTON_METRIC}>
							<RestrictionButtonContainer
								// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
								className={className || ''}
								data-testid="restriction-button"
							>
								<RestrictionButtonComponent
									hasDirectViewRestrictions={hasDirectViewRestrictions}
									hasInheritedViewRestrictions={hasInheritedViewRestrictions}
									hasRestrictions={hasRestrictions}
									onClick={(evt) =>
										restrictionsButtonClicked(
											evt,
											restrictionsDataContainer,
											hasDirectViewRestrictions,
											hasInheritedViewRestrictions,
											hasRestrictions,
										)
									}
									isDisabled={isDisabled}
									pageRestrictionsExperimentCohort={pageRestrictionsExperimentCohort}
								/>
							</RestrictionButtonContainer>
							<ExperienceSuccess name={VIEW_PAGE_RESTRICTIONS_BUTTON_EXPERIENCE} />
							<PageSegmentLoadEnd
								key={`end-${contentId}`}
								metric={RESTRICTIONS_BUTTON_METRIC}
								customData={{
									restrictionsButtonSSRRendered,
									restrictionsButtonSSRLocation,
									triggeredFrom,
								}}
							/>
						</SSRMouseEventWrapper>
					</GuestRequestToUpgradeNotificationsWrapper>
				</Fragment>
			);
		},
		[
			className,
			restrictionsButtonClicked,
			isDisabled,
			pageRestrictionsExperimentCohort,
			contentId,
			restrictionsButtonSSRRendered,
			restrictionsButtonSSRLocation,
			triggeredFrom,
			showFlag,
			intl,
			shouldRenderGuestUpgradeByRestrictions,
		],
	);

	const { restrictions, loading } = usePageRestrictionsContext();

	const isQueryLoading = !restrictions && loading;

	if (!contentId) return null;
	return (
		<Subscribe to={[RestrictionsDataContainer, DialogsStateContainer, FlagsStateContainer]}>
			{(
				restrictionsDataContainer: RestrictionsDataContainer,
				dialogs: DialogsStateContainer,
				flags: FlagsStateContainer,
			) => {
				if (
					isRestrictionsButtonClickedInSSR() &&
					shouldHideSpinner === false &&
					!shouldRenderGuestUpgradeByRestrictions
				) {
					return (
						<SSRActionLoadingSpinner
							spinnerId="restrictions-loading-spinner"
							actionType="restrictionsButton"
						/>
					);
				}
				if (isQueryLoading || (isGuest && !shouldRenderGuestUpgradeByRestrictions)) {
					return (
						<PlaceholderContainer
							isGuest={isGuest}
							data-test-id="restriction-placeholder-container"
						>
							<RestrictionsButtonPlaceholder />
						</PlaceholderContainer>
					);
				}

				const hasDirectViewRestrictions = Boolean(restrictions?.hasViewRestrictions);
				const hasInheritedRestrictions = Boolean(restrictions?.hasInheritedRestrictions);
				const hasRestrictions = Boolean(restrictions?.hasRestrictions);
				return restrictionButtonGenerator(
					restrictionsDataContainer,
					hasDirectViewRestrictions,
					hasInheritedRestrictions,
					hasRestrictions,
					dialogs,
					flags,
				);
			}}
		</Subscribe>
	);
};

type RestrictionsButtonProps = {
	className?: string;
	contentId?: string;
	onClick(): void;
	resetDialogState?(): void;
	isDisabled?: boolean;
	shouldHideSpinner?: boolean;
	restrictionsButtonSSRRendered?: boolean;
	restrictionsButtonSSRLocation?: string;
	triggeredFrom?: string;
	shouldRenderGuestUpgradeByRestrictions?: boolean;
} & Pick<RestrictionButtonComponentProps, 'pageRestrictionsExperimentCohort'>;
