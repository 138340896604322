import { defineMessages } from 'react-intl-next';

export const GuestRequestToUpgradeByRestrictions = defineMessages({
	flagTitle: {
		id: 'external-collab-ui.guest-request-to-upgrade-by-restrictions.title',
		defaultMessage: 'You can’t restrict content as a guest',
		description:
			'Flag notification title letting the user know they are a guest and cannot restrict content',
	},
	requestUpgradeFlagDescription: {
		id: 'external-collab-ui.guest-request-to-upgrade-by-restrictions.request-upgrade-body',
		defaultMessage: 'To restrict content, you need full access to Confluence.',
		description:
			'Context which explains that upgrading from a guest to a user unlocks more features',
	},
	requestPendingFlagDescription: {
		id: 'external-collab-ui.guest-request-to-upgrade-by-restrictions.request-pending-body',
		defaultMessage:
			'Your admin must approve your request and grant you full access to Confluence before you can restrict content.',
		description:
			'Flag notification description while request is pending that informs guest that they cannot access some features as a guest',
	},
});
